<template>
    <div class="main-page">
        <div class="container">
            <aside class="d-md-none">
                <app-bar
                    title="Login"
                    previousLink="/"
                />
            </aside>
            <div class="row">
                <div class="col-md-5 d-flex px-3">
                    <div class="form-login w-100">
                        <div class="group-title mb-4">
                            <span class="title-content-package">Masuk</span> <br>
                            <span class="fw-normal fs-12 color-neutral-600">Silahkan masuk dengan alamat email yang terdaftar</span>
                        </div>

                        <div class="w-full d-flex justify-content-between gap-3 mb-3">
                            <div
                                class="rounded-pill px-3 py-2 w-50 text-center cursor-pointer"
                                :class="{
                                    'bg-primary text-white': loginType === 'phone',
                                    'border border-2 border-primary text-primary': loginType !== 'phone',
                                }"
                                @click="changeType('phone')"
                            >
                                Nomor HP
                            </div>
                            <div
                                class="rounded-pill px-3 py-2 w-50 text-center cursor-pointer"
                                :class="{
                                    'bg-primary text-white': loginType === 'email',
                                    'border border-2 border-primary text-primary': loginType !== 'email',
                                }"
                                @click="changeType('email')"
                            >
                                Email
                            </div>
                        </div>

                        <div v-if="loginType === 'email'">
                            <Form
                                @submit="isLogin"
                                v-slot="{ errors }"
                            >
                                <label>Email atau No.Telepon</label>
                                <div class="input-group mb-3">
                                    <Field
                                        type="email"
                                        v-model="email"
                                        name="email"
                                        rules="required"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.email || errorMessage }"
                                        :disabled="loading.login"
                                        placeholder="Cth: nama@email.com" />
                                    <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.email || errorMessage }">
                                        <div class="show-password" type="button" @click="email = ''" :class="{'opacity-0':!email}">
                                            <i class="ri-close-circle-fill fs-24"></i></div>
                                    </span>
                                    <span v-if="!errors.email" class="invalid-feedback">{{ errorMessage }}</span>
                                    <ErrorMessage name="email" class="invalid-feedback" />
                                </div>
                                <label>Kata Sandi</label>
                                <div class="input-group mb-3">
                                    <Field
                                        v-model="password"
                                        :type="type"
                                        name="password"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.password || errorMessage }"
                                        id="password-visibility"
                                        rules="required"
                                        :disabled="loading.login"
                                        placeholder="Kata Sandi" />
                                    <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.password || errorMessage }">
                                        <div class="show-password" type="button" @click="type = showPassword(type)" :class="{'opacity-0':!password}">
                                            <i class="ri-eye-fill fs-24" v-if="type === 'password'"></i>
                                            <i class="ri-eye-off-fill fs-24" v-if="type === 'text'"></i>
                                        </div>
                                    </span>
                                    <span v-if="!errors.password" class="invalid-feedback">{{ errorMessage }}</span>
                                    <ErrorMessage name="password" class="invalid-feedback" />
                                </div>
                                <button
                                    type="submit"
                                    class="btn btn-primary w-100 btn-login p-md-3"
                                    :disabled="!email || !password || Object.keys(errors).length || loading.login">
                                    <p class="fst-normal fw-600 mb-0">
                                        {{ loading.login ? 'Proses Masuk..' : 'Masuk' }}
                                    </p>
                                </button>
                            </form>

                            <div class="d-flex justify-content-center px-2 my-3">
                                <router-link to="/login/forgot-password">
                                    <span class="fw-bold color-secondary-500">Lupa Kata Sandi?</span>
                                </router-link>
                            </div>
                            <div class="d-flex justify-content-center justify-content-md-start my-3">
                                <p class="fw-normal color-neutral-600">
                                    Belum punya akun?
                                    <router-link to="/register">
                                        <span class="fw-bold">Daftar Disini</span>
                                    </router-link>
                                </p>
                            </div>
                        </div>

                        <div v-else>
                            <Form
                                @submit="isLoginByPhone"
                                v-slot="{ errors }"
                            >
                                <label>No. Telp</label>
                                <div class="input-group w-full mb-3">
                                    <VueTelInput
                                        :value="phone"
                                        v-model="phone"
                                        name="phone"
                                        rules="required"
                                        mode="international"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.phone }"
                                        :inputOptions="{
                                        'placeholder': '8xxx xxxx xxxx'
                                        }"
                                        :validCharactersOnly="true"
                                        @input="phone"
                                    />
                                    <span
                                        class="input-group-text bg-grayscale border-0"
                                        :class="{ 'is-invalid': errors.phone || errorMessage }"
                                    >
                                        <div
                                            class="show-password"
                                            :class="{'opacity-0':!phone}"
                                            type="button"
                                            @click="phone = ''"
                                        >
                                            <i class="ri-close-circle-fill fs-24"></i>
                                        </div>
                                    </span>
                                    <span v-if="!errors.phone" class="invalid-feedback">{{ errorMessage }}</span>
                                    <ErrorMessage name="phone" class="invalid-feedback" />
                                </div>
                                <button
                                    type="submit"
                                    class="btn btn-primary w-100 btn-login p-md-3"
                                    :disabled="!phone || Object.keys(errors).length || loading.login">
                                    <p class="fst-normal fw-600 mb-0">
                                        {{ loading.login ? 'Proses Masuk..' : 'Masuk' }}
                                    </p>
                                </button>
                            </Form>
                        </div>
                    </div>
                </div>
                <div class="d-none d-md-block col-md-7">
                    <div class="frame-image vh-md-100 d-flex align-items-center justify-content-center">
                        <div>
                            <img src="@/assets/illustration.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import { showPassword, login, loginByPhone, loading } from '@/core/auth'
import { title } from '@/core/page'
import { nextTick, onMounted, ref } from 'vue'
import AppBar from '@/components/AppBar.vue'
import { showNotif } from '@/core/utility'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    AppBar,
    VueTelInput
  },
  setup: () => {
    const loginType = ref('phone')
    const type = ref('password')
    const email = ref('')
    const password = ref('')
    const phone = ref('')
    const errorMessage = ref('')
    const bindProps = ref({
      mode: 'international',
      defaultCountry: 'ID',
      onlyCountries: ['ID'],
      enabledCountryCode: true,
      enabledFlags: true,
      autocomplete: 'off',
      required: true
    })

    const changeType = (param) => {
      loginType.value = param
      email.value = ''
      phone.value = ''
      password.value = ''
    }

    onMounted(() => {
      nextTick(() => {
        title.value = 'Login'
      })
    })

    return {
      loading,
      email,
      password,
      phone,
      type,
      errorMessage,
      showPassword,
      login,
      loginByPhone,
      loginType,
      bindProps,
      changeType
    }
  },
  methods: {
    async isLogin () {
      try {
        const { status, message } = await this.login({
          email: this.email,
          password: this.password,
          token_login: null // sementara null aja dulu
        })

        // Jika sukses
        if (status) {
          // Redirect ke halaman tujuan
          if (this.$route.redirectedFrom) {
            return this.$router.push({ name: this.$route.redirectedFrom.name, params: this.$route.redirectedFrom.params })
          } else { // redirect ke home
            return this.$router.push({ name: 'Home' })
          }
        }

        this.errorMessage = message
      } catch (error) {
        this.errorMessage = error.response.data.message
      }
    },

    async isLoginByPhone () {
      try {
        if (this.phone.length < 10) {
          showNotif({
            status: 'error',
            message: 'Nomor telepon tidak valid.'
          })
        } else {
          const { status, message } = await this.loginByPhone({
            phone: this.phone.replace('+62', '0').replace(/\s/g, '')
          })

          if (status) {
            this.$router.push({
              name: 'OtpPhone',
              params: {
                phone: this.phone.replace('+62', '0').replace(/\s/g, '')
              }
            })
          } else {
            showNotif({
              status: 'error',
              message: message
            })
          }
        }
      } catch (error) {
        this.errorMessage = error.response.data.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .form-login {
        // padding-top: 2rem;

        @media (min-width: 520px) {
            padding-top: 25%;
        }
    }

    .form-control.is-invalid, .input-group-text.is-invalid {
        box-shadow: inset 0px -1px 0px #E21F39;
    }

    .form-control::placeholder {
        color: #CBD5E0;
    }

    .form-control, .input-group-text {
        background: none;
        border-radius: 0;
        box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
        padding: 8px 4px 8px 0px;
        font-size: 18px;
    }

    .btn-login {
        border: none;
        padding: 10px 20px;
    }

    .input-group>.form-control.form-error {
        background: #FFDDEB;
        color: #14142B;
    }

    .frame-image {
        background: #007a9826;
        width: 100%;
        height: 100%;
    }

    // .frame-image img {
    //     width: 90%;
    //     height: auto;
    // }

    .form-control, .input-group-text {
        background: none;
        border-radius: 0;
        box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
        padding: 8px 4px 8px 0px;
        font-size: 18px;
    }

    .vue-tel-input {
        border: none;
    }

    @media (min-width: 360px) and (max-width: 767px) {
        .form-login {
            padding-right: 1rem !important;
        }

        .frame-image {
            position: relative;
            background: #007a9826;
            width: 100%;
        }
    }
</style>
